<template>
  <div class="table-article-list__tr">
    <div class="table-article-list__td table-article-list__td--type">
      <div>
        <span v-if="article.type === 'article'" :title="$t('article.internal')" class="table-article-list__type">
          <IconArticleInternal class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'reportage'" :title="$t('article.article_type.onlineReportage')" class="table-article-list__type">
          <IconArticleInternal class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'external'" :title="$t('article.external')" class="table-article-list__type">
          <IconArticleExternal class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'video'" :title="$t('article.video')" class="table-article-list__type">
          <IconArticleVideo class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'photostory'" :title="$t('article.photostory')" class="table-article-list__type">
          <IconArticlePhotostory class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'quiz'" :title="$t('article.quiz')" class="table-article-list__type">
          <IconArticleQuiz class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'recipe'" :title="$t('article.recipe')" class="table-article-list__type">
          <IconArticleRecipe class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'test'" :title="$t('article.test')" class="table-article-list__type">
          <IconArticleTest class="table-article-list__icon-article" />
        </span>
        <span v-if="article.type === 'longform'" :title="$t('article.longform')" class="table-article-list__type">
          <IconArticleLongForm class="table-article-list__icon-article" />
        </span>
        <span
          v-if="article.type === 'disease'"
          :title="$t('article.article_type.disease')"
          class="table-article-list__type"
        >
          <span class="table-article-list__td-desc">{{ $t("article.list.type") }}</span>
          <IconArticleDisease class="table-article-list__icon-article" />
        </span>
        <span
          v-if="article.type === 'encyclopedy'"
          :title="$t('article.article_type.encyclopedy')"
          class="table-article-list__type"
        >
          <span class="table-article-list__td-desc">{{ $t("article.list.type") }}</span>
          <IconArticleEncyclopedy class="table-article-list__icon-article" />
        </span>
      </div>
    </div>
    <div class="table-article-list__td">
      <span v-if="article.setting.status === 'draft'" v-html="getActivityByDocId(article.documentId)"></span>
      <span class="table-article-list__td-desc">{{ $t("article.list.title") }}</span>
      <i v-if="article.originalDocumentId" class="fas fa-share" :title="$t('article.transfer_to_export.transferred_state')" />
      <span class="table-article-list__td-title">{{ article.field.title }}</span>
    </div>
    <div class="table-article-list__td">
        <span class="table-article-list__td-desc">
          {{ $t("article.list.main_rubric") }}<br>
          {{ $t("article.list.site") }}
        </span>
      <strong class="table-article-list__rubric">{{ rubricName }}</strong>
      <span class="table-article-list__site">
          {{ siteNameById(article.site) }}
        </span>
    </div>
    <div class="table-article-list__td">
      <span class="table-article-list__td-desc">{{ $t("article.list.author") }}</span>
      <template v-for="(author, index) in article.authors">
          <span class="table-article-list__author" :key="author + index">
            <TableArticleListAuthor
              :index="index"
              :author="author"
              :article="article"
            />
          </span>
      </template>
    </div>
    <div class="table-article-list__td">
      <div>
          <span class="table-article-list__td-desc">
            {{ $t("article.list.order_date") }}<br>
            {{ $t("article.list.last_modified") }}
          </span>
        <span class="table-article-list__order-date">{{ article.orderDate | prettyDateTime }}</span>
        <small v-if="article.draftModifiedAt" class="table-article-list__modified-at">
          {{ article.draftModifiedAt | prettyDateTime }} {{ userNameById(article.draftModifiedBy) }}
        </small>
      </div>
      <small v-if="!article.draftModifiedAt" class="table-article-list__created-by">
        {{ article.firstPublishedDate | prettyDateTime }} {{ userNameById(article.createdBy) }}
      </small>
    </div>
    <div class="table-article-list__td">
      <span class="table-article-list__td-desc">{{ $t("article.list.status") }}</span>
      <ArticleStatus :article="article"/>
    </div>
    <div class="table-article-list__td table-article-list__td--btns">
      <span class="table-article-list__td-desc">{{ $t("actions") }}</span>
      <BtnCreateCampaign :article="article" />
      <BtnArticlePreview
        :article="article"
        :disable-on-expired="true"
        data-test="article_list_btn_preview"
      />
      <BtnDetail
        data-test="article_list_btn_detail"
        :link-to="{ name: 'article_detail',params: { id: article.id }}"
        :title="$t('route.article_detail')"
      />
      <BtnCopyToClipboard
        :data="article.documentId"
        :notify-message="$t('notify.id_was_copied')"
        data-test="article_list_btn_copy"
      />
      <BtnEdit
        v-if="article.setting.status === 'draft' || article.setting.status === 'review'"
        :title="$tc('article.edit')"
        @click="() => isAllowedToEdit(article)"
        :data-test="'article_list_btn_edit'"
        :btn-is-disabled="getActivityByDocId(article.documentId, true)"
      />
    </div>
  </div>
</template>

<script>
import BtnEdit from '@/components/buttons/BtnEdit'
import TableArticleListAuthor from '@/components/table/TableArticleListAuthor'
import BtnDetail from '@/components/buttons/BtnDetail'
import ArticleStatus from '@/components/article/ArticleStatus'
import BtnArticlePreview from '@/components/buttons/BtnArticlePreview'
import BtnCreateCampaign from '@/components/buttons/BtnCreateCampaign'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import IconArticleInternal from '@/assets/img/svg/article-internal.svg?inline'
import IconArticleExternal from '@/assets/img/svg/article-external.svg?inline'
import IconArticleEncyclopedy from '@/assets/img/svg/article-encyclopedy.svg?inline'
import IconArticlePhotostory from '@/assets/img/svg/article-photostory.svg?inline'
import IconArticleQuiz from '@/assets/img/svg/article-quiz.svg?inline'
import IconArticleLongForm from '@/assets/img/svg/article-longform.svg?inline'
import IconArticleDisease from '@/assets/img/svg/article-disease.svg?inline'
import IconArticleRecipe from '@/assets/img/svg/article-recipe.svg?inline'
import IconArticleTest from '@/assets/img/svg/article-test.svg?inline'
import IconArticleVideo from '@/assets/img/svg/article-video.svg?inline'
import ArticleList from '@/components/mixins/ArticleList'

export default {
  name: 'TableTr',
  props: {
    article: {
      type: Object,
      required: true
    },
    isAllowedToEdit: {
      type: Function,
      required: true
    }
  },
  mixins: [ArticleList],
  components: {
    BtnEdit,
    TableArticleListAuthor,
    BtnDetail,
    ArticleStatus,
    BtnArticlePreview,
    BtnCreateCampaign,
    BtnCopyToClipboard,
    IconArticleInternal,
    IconArticleExternal,
    IconArticleEncyclopedy,
    IconArticlePhotostory,
    IconArticleQuiz,
    IconArticleLongForm,
    IconArticleDisease,
    IconArticleRecipe,
    IconArticleTest,
    IconArticleVideo
  },
  computed: {
    rubricName () {
      // rubrics are already loaded together with articles in vuex store 'article/fetch'
      return this.rubricNameById(this.article.mainRubric)
    }
  }
}
</script>
